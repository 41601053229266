<template>
    <div class="login">
        <h1>Login</h1>
        <p>
            <input v-model="username" placeholder="Username"/>
        </p>
        <p>
            <input v-model="password" placeholder="Password"/>
        </p>
        <p>
            <button v-on:click="loginAction">Submit</button>
        </p>

    </div>
</template>


<script>
    import router from "../router";
    import SecurityService from "../services/SecurityService";

    export default {
        name: "Login",
        data: function () {
            return {
                username: '',
                password: ''
            }
        },

        methods: {
            loginAction() {
                SecurityService.retrieveTokenData(this.username, this.password)
                .then((response) => {
                    if (response.token !== undefined) {
                        router.push({name: "Home"})
                    }
                })
            }
        }


    }
</script>
